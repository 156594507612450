import Vue from 'vue'
import 'element-ui/lib/theme-chalk/index.css';
import { 
    Button,
    Header,
    Aside,
    Main,
    Container,
    Form,
    FormItem,
    Row,
    Col,
    Input,
    Radio,
    RadioGroup,
    Checkbox,
    CheckboxGroup,
    Loading,
    Message,
    MessageBox,
    Dialog,
    DatePicker,
    Select,
    Option,
    Link,
    InfiniteScroll,
    Drawer,
    Divider
} from 'element-ui'

Vue.use(Button)
Vue.use(Header)
Vue.use(Aside)
Vue.use(Main)
Vue.use(Container)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Row)
Vue.use(Col)
Vue.use(Input)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Loading.directive)
Vue.use(Dialog)
Vue.use(DatePicker)
Vue.use(Select)
Vue.use(Option)
Vue.use(Link)
Vue.use(InfiniteScroll)
Vue.use(Drawer)
Vue.use(Divider)
Vue.prototype.$loading = Loading.service;
Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;