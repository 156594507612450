import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    name: "" || localStorage.name,
    account: "" || localStorage.account,
    customerId: "" || localStorage.customerId,
    coefficient: "" || localStorage.coefficient,
    coefficientStatus: "" || localStorage.coefficientStatus
  },
  getters: {
    
  },
  mutations: {
    SET_NAME(state, name) {
      state.name = name
      localStorage.setItem('name', name);
    },
    SET_ACCOUNT(state, account) {
      state.account = account
      localStorage.setItem('account', account);
    },
    SET_CUSTOMERID(state, customerId) {
      state.customerId = customerId
      localStorage.setItem('customerId', customerId);
    },
    SET_COEFFICIENT(state, coefficient) {
      state.coefficient = coefficient
      localStorage.setItem('coefficient', coefficient);
    },
    SET_COEFFICIENTSTATUS(state, coefficientStatus) {
      state.coefficientStatus = coefficientStatus
      localStorage.setItem('coefficientStatus', coefficientStatus);
    }
  },
  actions: {

  },
  modules: {
  }
})
