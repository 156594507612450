<template>
  <div class="home">
    <el-container>
      <el-header>
        <div class="logo">
          <img width="200" src="../assets/logo.png" alt="" />
        </div>
        <div class="time_container">
          <div class="car">
            <img width="30" height="30" src="../assets/2.png" alt="" />
          </div>
          <div class="clock">
            <img width="40" height="40" src="../assets/1.png" alt="" />
          </div>
        </div>
        <div class="user">
          <span>{{ $store.state.name }}</span>
        </div>
      </el-header>
      <el-container>
        <el-aside width="100px">
          <div class="nav_container">
            <div
              class="nav_item"
              v-for="(item, index) in navArr"
              :key="index"
              :class="[idx === index ? 'active' : '']"
              @click="changeIndex(index)"
            >
              <img :src="item.url" alt="" />
            </div>
          </div>
        </el-aside>
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      idx: 0,
      navArr: [
        {
          url: require("../assets/3.png"),
          router: "/ltl/estimate",
        },
        { 
          url: require("../assets/4.png"), 
          router: "/my/order"
        },
        { 
          url: require("../assets/5.png"), 
          router: "/my/account"
        },
        {
          url: require("../assets/link.png"),
          link: "http://calculate.alluvionlogistics.com/"
        }
      ]
    };
  },
  methods: {
    changeIndex(index) {
      if(this.idx === index) return false;
      
      if(index === 3) {
        window.open(this.navArr[index].link);
        return false
      }
      this.idx = index;
      this.$router.push(this.navArr[index].router);
    },
  },
  created() {
    this.idx = this.navArr.findIndex(item => item.router === this.$route.path);
  },
  mounted() {
    let time_container = document.querySelector(".time_container").clientWidth;
    let car = document.querySelector(".car");
    let clock  = document.querySelector(".clock").clientWidth; 
    let preDate = new Date(new Date(new Date().getTime() - 24 * 60 * 60 * 1000).toDateString() + ' 21:00').getTime();
    let nowDate = new Date().getTime() - preDate;
    let percent = nowDate / 86400000;
    let maxWidth = time_container - car.clientWidth - clock;  
    let speed = maxWidth / 86400000;
    let x = maxWidth * percent;
    setInterval(() => {
      x += speed;
      car.style.transform = `translateX(${x + 'px'})`;
    }, 1);
  },
};
</script>

<style scoped>
.home {
  height: 100%;
}
.el-header {
  height: 100px !important;
  background-color: #ecf0f3;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logo {
  width: 200px;
}
.time_container {
  width: calc(80% - 20px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-left: 1px dashed #6096e6;
  border-right: 1px dashed #6096e6;
  padding: 0 40px 0 0;
}
.user {
  width: 100px;
  color: #7595c0;
  text-align: center;
}
.el-container {
  height: 100%;
  overflow: hidden;
}
.el-aside {
  background-color: #ecf0f3;
}
.nav_container {
  height: 100%;
}
.nav_item {
  padding: 20px 0;
  cursor: pointer;
}
.nav_item img {
  width: 50px;
  height: 50px;
  margin: 0 auto;
  display: block;
}
.nav_item.active img {
  background: #fff;
  padding: 10px;
  width: 60px;
  height: 60px;
  border-radius: 5px;
  transition: all 0.3s;
}
img {
  display: block;
}
.el-main {
  padding: 0;
  overflow: hidden;
}
</style>