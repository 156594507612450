import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/home',
    name: 'home',
    redirect: '/ltl/estimate',
    component: HomeView,
    children: [
      {
        path: '/ltl/estimate',
        name: 'ltl-estimate',
        component: () => import('../views/LtlEstimate.vue')
      },
      {
        path: '/my/order',
        name: 'my-order',
        component: () => import('../views/MyOrder.vue')
      },
      {
        path: '/my/account',
        name: 'my-account',
        component: () => import('../views/MyAccount.vue')
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
